import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n';
import messages from './translations';
import vueSeo from '@deveodk/vue-seo';
import VeeValidate, {Validator} from 'vee-validate'
import de from 'vee-validate/dist/locale/de'
import en from 'vee-validate/dist/locale/en'
import {library} from '@fortawesome/fontawesome-svg-core'

import {
    faClipboard,
    faFilm,
    faHistory,
    faLightbulb,
    faListCheck,
    faStar,
    faVideo
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// import VueAnalytics from 'vue-analytics';
import VueCookie from 'vue-cookie'
import VueClipboard from 'vue-clipboard2'

import UKExtendedIcons from 'uikit-icons-extended/dist/js/uk-extended.min.js';
import VCalendar from 'v-calendar';

UIkit.use(UKExtendedIcons);

Vue.prototype.languages = ['de', 'en'];
Vue.prototype.apiTimeout = 6000;
Vue.prototype.cookieMaxExpiry = new Date(2147483647 * 1000);

Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages
});


Vue.use(vueSeo);
Vue.use(VueClipboard);

Vue.use(VCalendar);

Validator.localize({de: de, en: en});
Vue.use(VeeValidate, {locale: 'de'});

library.add(faListCheck)
library.add(faVideo)
library.add(faFilm)
library.add(faLightbulb)
library.add(faStar)
library.add(faHistory)
library.add(faClipboard)
Vue.component('font-awesome-icon', FontAwesomeIcon)


window.UIkit = UIkit;
UIkit.use(Icons);

/*
Vue.use(VueAnalytics, {
    id: 'UA-109420283-1',
    disabled: true,
    beforeFirstHit() {
        Vue.$ga.set('anonymizeIp', true)
    },
    router,
    checkDuplicatedScript: true
});
 */


Vue.use(VueCookie);

Vue.config.productionTip = false;


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
